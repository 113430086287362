/*------------------------------------------

Project Name : Jobs Portal
Desgined By  : eCreative solutions
Developed By : sharjeel
Updated        :  17/Nov/19

Table Of Index
------------------

1. Base css

2. Typography css

3. Header css

4. Top Search css

5. Map search css

6. Revolution slider css

7. How it works css

8. Top Employers css

9. Home Jobs css

10. Video css

11. Testimonials css

12. App Box css

13. Search List css

14. Job Detail css

15. Cms Pages css

16. FAQs css css

17. User Account css

18. 404 Page css

19. About Us css

20. Pay Packages css

21. Blog Listing css

22. Blog Grid css

23. Blog Detail css

24. Blog sidebar css

25. Footer css

26. Media Queries css

------------------------------------------*/

/************************************************

           ==Start Base css==

*************************************************/

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800|Open+Sans:400,400i,600,700&amp;display=swap");
/* CSS Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  font-weight: 600;
}
.hidden {
  display: none;
}
a {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  color: #2e499c;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:focus {
  outline: 0 solid;
}
input:focus,
textarea:focus {
  outline: none;
}
p {
  line-height: 22px;
}
img {
  max-width: 100%;
}
select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.margin-top30 {
  margin-top: 30px;
}
.margin-left80 {
  margin-left: 80px;
}
/*Common*/

.section {
  padding: 50px 0;
  overflow: hidden;
}
.greybg {
  background: #f6f6f6;
}
/*Section Title*/

.titleTop {
  margin-bottom: 10px;
}
.titleTop .subtitle {
  font-size: 18px;
  font-weight: 600;
  color: #2e499c;
  letter-spacing: 3px;
}
.titleTop h3 {
  font-size: 36px;
  font-weight: 600;
  color: #000;
}
.titleTop h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}
.titleTop h3 span {
  color: #2e499c;
  font-weight: 300;
}
.btn-primary {
  border: none;
}
.modal-title {
  font-size: 20px;
}
.btn-close,
.close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  /* background: transparent url(../images/times.svg) center/1em auto no-repeat; */
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
  text-shadow: none;
}

/************************************************

           ==Tyography css==

*************************************************/

.mt-wrapper {
  margin-bottom: 30px;
}
h1,
.h1 {
  font-size: 45px;
}
h2,
.h2 {
  font-size: 30px;
}
h3 {
  font-size: 25px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
#message-error {
  color: red;
  padding-top: 6px;
  display: inline-block;
}
.button-orng {
  background: #ffb72f;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#ffb72f),
    to(#ff9000)
  );
  background: -webkit-linear-gradient(#ffb72f, #ff9000);
  background: -moz-linear-gradient(#ffb72f, #ff9000);
  background: -ms-linear-gradient(#ffb72f, #ff9000);
  background: -o-linear-gradient(#ffb72f, #ff9000);
  background: linear-gradient(#ffb72f, #ff9000);
  padding: 7px 20px;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}
.button-orng:hover {
  text-decoration: none;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#ff9000),
    to(#ffb72f)
  );
  background: -webkit-linear-gradient(#ff9000, #ffb72f);
  background: -moz-linear-gradient(#ff9000, #ffb72f);
  background: -ms-linear-gradient(#ff9000, #ffb72f);
  background: -o-linear-gradient(#ff9000, #ffb72f);
  background: linear-gradient(#ff9000, #ffb72f);
  color: #fff;
}
.sitebtn {
  -webkit-box-shadow: 3px 4px rgba(255, 209, 95, 0.3);
  -moz-box-shadow: 3px 4px rgba(255, 209, 95, 0.3);
  box-shadow: 3px 4px rgba(255, 209, 95, 0.3);
  background: #ff9000;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 4px;
}
.sitebtn:hover {
  background: #111;
  color: #fff;
  text-decoration: none;
}
.sitebtn i,
.button-orng i {
  padding-right: 7px;
  font-size: 16px;
  display: inline-block;
}
.grey_sitebtn {
  -webkit-box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  -moz-box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  background: #444;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 4px;
}
.grey_sitebtn:hover {
  background: #111;
  color: #fff;
  text-decoration: none;
}
.grey_sitebtn i {
  padding-right: 7px;
  font-size: 16px;
  display: inline-block;
}
.gree_sitebtn {
  -webkit-box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  -moz-box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  box-shadow: 3px 4px rgba(202, 255, 127, 0.3);
  background: #77c30a;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 4px;
}
.gree_sitebtn:hover {
  background: #111;
  color: #fff;
  text-decoration: none;
}
.gree_sitebtn i {
  padding-right: 7px;
  font-size: 16px;
  display: inline-block;
}
/*Shortcode*/

.mt-shortcodes-d-styles {
  padding-bottom: 30px;
}
.mt-shortcodes-default-custom-styles .row1 {
  padding-bottom: 55px;
}
.mt-list-style h4 {
  font-size: 16px;
  font-weight: 400;
  color: #252525;
}
.mt-list-style ul {
  padding: 0;
  list-style: none;
}
.mt-list-style ul li {
  line-height: 36px;
}
.mt-list-style ul li a {
  color: #737373;
  line-height: 36px;
}
.mt-list-style ul li span {
  font-size: 16px;
  margin-right: 10px;
}
.mt-list-style-blue {
  color: #2e499c;
}
/*Buttons*/

.mt-style-button {
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 20px;
}
.mt-style-button1 .mt-style-button {
  background: #2e499c;
  color: #fff;
  border-radius: 3px;
}
.mt-style-button2 .mt-style-button {
  background: #ddd;
  color: #000000;
  border-radius: 3px;
}
.mt-style-button3 .mt-style-button {
  background: #fff;
  color: #252525;
  border: 2px solid #42454a;
  border-radius: 3px;
}
.mt-style-button41 .mt-style-button {
  background: #55adff;
  color: #fff;
  border-radius: 30px;
}
.mt-style-button42 .mt-style-button {
  background: #ffb72f;
  color: #fff;
  border-radius: 0px;
}
.mt-style-button43 .mt-style-button {
  background: #5fd33f;
  color: #fff;
  border-radius: 3px;
}
.mt-style-button.large {
  line-height: 51px;
  font-size: 16px;
  letter-spacing: 1px;
}
.mt-style-button.normal {
  line-height: 40px;
  font-size: 12px;
  letter-spacing: 1px;
}
.mt-style-button.small {
  line-height: 30px;
  font-size: 9px;
  letter-spacing: 1px;
}
.mt-style-button1 .mt-style-button:hover {
  background: #333;
  color: #fff;
  text-decoration: none;
}
.mt-style-button2 .mt-style-button:hover {
  background: #2e499c;
  color: #fff;
  border-radius: 3px;
  text-decoration: none;
}
.mt-style-button3 .mt-style-button:hover {
  background: #2e499c;
  color: #fff;
  border-radius: 3px;
  border: 2px solid #2e499c;
  text-decoration: none;
}
.mt-style-button41 .mt-style-button:hover {
  background: #333;
  color: #fff;
  border-radius: 30px;
  text-decoration: none;
}
.mt-style-button42 .mt-style-button:hover {
  background: #000;
  color: #fff;
  border-radius: 0px;
  text-decoration: none;
}
.mt-style-button43 .mt-style-button:hover {
  background: #000;
  color: #fff;
  border-radius: 3px;
  text-decoration: none;
}
/************************************************

           ==Header css==

*************************************************/

.header {
  padding: 0;
}
.header .col-md-5 {
  padding-right: 0;
}
.header .logo {
  display: inline-block;
  margin: 5px 0 5px 0;
}

.navbar {
  padding: 0;
}

.navbar-default {
  background: none;
  border: none;
  margin-bottom: 0;
  min-height: auto;
  float: right;
}
.navbar {
  background: none;
}
.navbar-collapse {
  padding: 0;
}
.navbar-nav > li {
  padding: 0 15px;
}
.navbar-nav > li > a {
  font-weight: 600;
  padding: 16px 0;
  border-bottom: 2px solid #fff;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 16px 0;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background: none;
}
.navbar-nav > li > a:hover,
.navbar-nav > li:hover > a,
.navbar-nav > li.active > a {
  border-bottom-color: #2e499c;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: #fff;
}

.userbtn {
  margin-top: 7px;
}
.dropdown-menu {
  border-radius: 5px;
  padding: 0;
  border: none;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
}
.navbar-nav > li:hover .dropdown-menu {
  display: block;
}

.navbar-expand-lg .navbar-nav .dropdown-menu > li > a {
  font-weight: 600;
  color: #555;
  padding: 15px 20px;
  font-size: 14px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu > li > a:hover {
  background: #2e499c;
  color: #fff;
}
.navbar-collapse.in {
  overflow: inherit;
  height: auto;
  padding: 0 15px;
}
.navbar-nav li.postjob,
.navbar-nav li.jobseeker {
  padding: 0;
}
.navbar-expand-lg .navbar-nav .nav-link.register {
  background: #2e499c;
  color: #fff;
  padding: 10px 18px;
  border-radius: 40px;
  margin-top: 8px;
}
.navbar-nav li.jobseeker a {
  background: #666;
  color: #fff;
  padding: 16px 15px;
  border-bottom-color: #666;
}
.navbar-nav li > a.postjob:hover,
.navbar-nav li > a.jobseeker:hover {
  text-decoration: none;
  color: #fff;
  background: #000;
}
.navbar-nav > li.userbtn > a {
  padding: 0;
  border: none;
  padding-top: 8px;
}
.navbar-nav > li.userbtn img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 3px;
}
.navbar-nav > li.userbtn .dropdown-menu {
  left: auto;
  right: 0;
  width: 200px;
  top: 95%;
}

.navbar-header {
  display: none;
}

/************************************************

           ==Top Search css==

*************************************************/

.searchwrap {
  /* background:url(../images/search-bg.png) no-repeat bottom;	 */
  padding: 100px 0;
  height: 800px;
}
.searchwrap h3 {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  margin-bottom: 40px;
}
.searchwrap h3 span {
  display: block;
  color: #2e499c;
  font-weight: 700;
}

.searchwrap .col-lg-5 {
  position: relative;
}
.searchwrap .searchtpimg {
  -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2s linear infinite alternate;
  -o-animation: hero-thumb-animation 2s linear infinite alternate;
  animation: hero-thumb-animation 2s linear infinite alternate;
}

@keyframes hero-thumb-animation {
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(100px);
  }
}

.loginpageimg {
  text-align: center;
}
.divider-text-center {
  position: relative;
  text-align: center;
  margin-bottom: 35px;
}
.divider-text-center::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #e0e6f6;
  top: 50%;
  left: 0;
  z-index: 1;
}
.divider-text-center span {
  display: inline-block;
  background-color: #fff;
  padding: 0 15px;
  z-index: 2;
  position: relative;
}

.searchbar {
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 10%);
}

.srchbox {
  -webkit-transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -ms-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition: background 0.5s ease;
}

h3.seekertxt {
  text-align: left;
  color: #000;
  font-size: 48px;
  font-weight: 700;
}
h3.seekertxt span {
  color: #009c46;
}

.srchbox label {
  display: block;
  color: #000;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}
.additional_fields {
  margin-top: 15px;
}

.additional_fields .form-control {
  margin-bottom: 15px;
}
.searchbar .form-control {
  height: 50px;
  padding: 13px 15px;
  font-size: 16px;
  border-radius: 0;
  border: none;
  appearance: auto;
}
.searchbar select.form-control {
  border-left: 1px solid #eee;
}
.form-control:focus {
  box-shadow: none;
}

.srchbtn {
  text-align: center;
}
.searchbar .btn {
  background: #2e499c;
  color: #fff;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 13px 20px;
  border-radius: 8px !important;
  margin-left: 10px !important;
}

.seekersrch .form-control {
  box-shadow: none;
}
.seekersrch .btn {
  padding: 13px 20px;
}

.getstarted {
  text-align: center;
  margin-top: 50px;
}
.getstarted a {
  display: inline-block;
  border: 3px solid #fff;
  padding: 12px 25px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.getstarted a i {
  margin-right: 10px;
}
.getstarted a:hover {
  background: #2e499c;
  text-decoration: none;
}

.slidersearch .searchbar {
  background: #f2f6fd;
  padding: 25px;
  margin-top: -50px;
  z-index: 5;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 6px 12px rgb(0 0 0 / 5%);
}

/************************************************

           ==map search css==

*************************************************/

#map_canvas {
  height: 450px;
  width: 100%;
}
.infobox-wrap {
  width: 340px;
  padding: 10px 5px;
}
.infobox-wrap .infobox-figure {
  width: 150px;
  float: left;
}
.infobox-wrap .infobox-content {
  width: 180px;
  float: left;
  padding-left: 15px;
  text-align: left;
}
.infobox-wrap .infobox-content h5 {
  text-align: left;
  margin: 0;
  font-size: 20px;
}
.infobox-wrap .infobox-content .address p {
  text-align: left;
  color: #999;
  font-weight: 600;
}
.infobox-wrap .infobox-rattings {
  margin: 10px 0 15px;
}
.infobox-wrap .infobox-rattings i {
  margin-right: 2px;
}
.infobox-wrap .infobox-figure img {
  max-width: 100% !important;
}
.infobox-rattings i {
  color: #ff9600;
  font-size: 18px;
}
#map-section .form-control {
  height: auto;
  border-radius: 0;
  padding: 13px 15px;
  border: none;
  font-size: 18px;
}
#map-section .btn {
  background: #2e499c;
  border-radius: 0;
  color: #fff;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
}
.searchblack {
  margin-top: -460px;
  z-index: 10;
  position: relative;
  max-width: 555px;
  float: right;
}

.searchblack .searchbar {
  margin-top: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.searchblack h3 {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  margin-bottom: 40px;
}
.searchblack h3 span {
  display: block;
  color: #2e499c;
  font-weight: 700;
}

/*********************************************

		Revolution slider

**********************************************/
.tp-bannertimer {
  display: none;
}
.tp-banner-container {
  position: relative;
  z-index: 1;
  padding: 0;
  width: 100%;
}
.tp-banner {
  position: relative;
  width: 100%;
}
.tp-caption {
  line-height: normal !important;
}
.tp-caption a {
  color: inherit !important;
}
.tp-bullets.simplebullets.round .bullet {
  border: 3px #fff solid;
  border-radius: 50%;
  background-image: none !important;
}
.tparrows {
  width: auto !important;
  height: auto !important;
  background-image: none !important;
}
.tp-arr-allwrapper {
  margin: 0 10px;
  width: 50px;
  height: 60px;
}
.tp-arr-allwrapper:hover .tp-arr-iwrapper {
  color: #fff;
}
.tp-leftarrow .tp-arr-allwrapper {
}
.tp-rightarrow .tp-arr-allwrapper {
}
.tp-arr-iwrapper {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: "FontAwesome";
  line-height: 60px;
}
.tp-leftarrow .tp-arr-iwrapper {
}
.tp-leftarrow .tp-arr-iwrapper:before {
  content: "\f104";
}
.tp-rightarrow .tp-arr-iwrapper:before {
  content: "\f105";
}
.tp-rightarrow .tp-arr-iwrapper {
}
.slide-h1 {
  margin: 5px 0 !important;
  padding: 0 0 10px !important;
  padding-bottom: 5px !important;
  color: #444;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 57px;
}
.slide-h2 {
  margin: 5px 0 !important;
  padding: 0 0 10px !important;
  padding-bottom: 5px !important;
  color: #555;
  font-weight: bold;
  font-size: 55px;
  line-height: 100%;
}
.big-font {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 60px;
}
.large-desc {
  font-size: 16px;
}
.slide-h3 {
  color: #fff;
  font-size: 31px;
  line-height: 100%;
}
.slide-h3 span {
  text-transform: uppercase;
  font-weight: bold;
}
.slide-head {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 30px;
}
.slide-desc {
  font-size: 22px;
  line-height: 150%;
}
.main-title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 70px;
}
.icon-cont {
  padding: 15px 40px;
  border-radius: 10px;
  text-align: center;
}
.icon-cont:after {
  position: absolute;
  bottom: -10px;
  left: 40%;
  z-index: 5;
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 10px;
  border-style: solid;
  content: "";
}
.icon-cont i {
  margin-bottom: 10px;
  color: #fff;
  font-size: 50px;
}
.icon-cont span {
  display: block;
  padding: 0 0 5px;
  color: #fff;
  text-align: center;
  font-size: 18px;
}
a.wit-btn {
  background: #fff;
  color: #333 !important;
  text-transform: uppercase;
}
.wit-line {
  width: 5%;
  height: 1px;
  background: #fff;
}
.vert-line {
  min-height: 270px;
  width: 1px;
  background: #fff;
}
.subTxt {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
.large-title {
  color: #222;
  font-size: 60px;
  font-weight: 800;
  text-transform: uppercase !important;
}
.large-light-title {
  color: #787878 !important;
  font-size: 70px;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/*messages*/
.messageWrap {
  padding: 40px 0px;
  position: relative;
}
.message-body {
  background-color: #f0f0f0;
  overflow: hidden;
}

.message-body .row {
  margin: 0;
}
.message-body .col-lg-4,
.message-body .col-lg-8 {
  padding: 0;
}

.messages::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  transition: 0.3s background;
}
.messages::-webkit-scrollbar-thumb {
  background: transparent;
}
.messages:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

.message-history::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  transition: 0.3s background;
}
.message-history::-webkit-scrollbar-thumb {
  background: transparent;
}
.message-history:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

.message-inbox {
  border-right: 4px solid #eee;
}
.message-header {
  padding: 0;
}
.message-header h4 {
  display: inline-block;
  font-size: 18px;
  color: #232323;
  margin: 0;
}
.message-inbox .message-header span {
  float: right;
}
.message-inbox .message-header span + span {
  margin-right: 10px;
}
.message-inbox .message-header span a {
  color: #232323;
}
.message-inbox .message-header span a.active {
  color: #e52d27;
}
.message-inbox .list-wrap {
  position: relative;
  height: 550px;
  overflow: hidden;
  background: #fff;
}
.message-history {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
.message-history li {
  border-bottom: 1px solid #eee;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.message-history li a {
  display: block;
  overflow: hidden;
  padding: 15px 20px;
  text-decoration: none;
}
.message-history .active {
  background-color: #f8f8f8;
}
.message-history li:hover {
  background-color: #f8f8f8;
}
.message-history li .user-name p {
  font-size: 13px;
  color: #666;
  line-height: 1.5em;
  margin-top: 5px;
}
.message-history li .image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}
.message-history li .image img {
  width: 100%;
}
.message-history li .user-name {
  position: relative;
  margin-left: 65px;
}
.message-history li .user-name .author {
  margin-top: 13px;
}
.message-history .message-grid .user-name .author span {
  color: #232323;
}
.message-history li .user-name .author span {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.message-history .message-grid .user-name .author .user-status {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #2db82d;
  border-radius: 50%;
  margin-left: 5px;
}
.message-history li .user-name .time {
  position: absolute;
  top: 2px;
  right: 0;
}
.message-history li .user-name .time span {
  font-size: 13px;
  color: #777;
}
.icon-envelope {
  content: "\f003";
  font-family: "FontAwesome";
}
.ps-scrollbar-x-rail {
  display: none;
}
.ps-container > .ps-scrollbar-y-rail {
  position: absolute;
  right: 0;
  width: 5px;
  height: 100%;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  right: 0;
  width: 3px;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  -o-transition: -o-transform 0.3s ease;
  transition: transform 0.3s ease;
}
.message-details .list-wraps {
  position: relative;
  height: 590px;
  overflow: hidden;
}
.ps-container:hover > .ps-scrollbar-y-rail > .ps-scrollbar-y,
.ps-container:active > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  opacity: 1;
}
.message-details {
  position: relative;
  padding: 0;
  overflow: hidden;
  background: #fff;
}
.message-details .author {
  overflow: hidden;
}
.message-details .author .image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  float: left;
  margin-right: 18px;
}
.message-details .author .author-name {
  display: block;
  font-size: 16px;
  color: #232323;
  line-height: 1.5em;
  font-weight: 600;
}
.message-details .author em {
  font-size: 14px;
  font-style: italic;
  color: #666;
}
.message-details h2 {
  color: #232323;
  font-size: 18px;
  font-weight: 600;
  line-height: 60px;
  margin: 0;
  text-transform: capitalize;
}
.message-details h2 a {
  color: #232323;
}
.message-details .list-wraps {
  position: relative;
  height: 590px;
  overflow: hidden;
}
.messages {
  list-style: outside none none;
  margin: 0;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 440px;
}
.messages > li {
  margin-bottom: 10px;
}
.friend-message .profile-picture {
  float: left;
}
.messages .profile-picture {
  height: 40px;
  margin: 0;
  width: 40px;
}
.messages .profile-picture img {
  border: medium none;
  border-radius: 50%;
}
.friend-message .message {
  background-color: #fff;
  margin-left: 50px;
}
.friend-message .message::before {
  border-bottom: 10px solid transparent;
  border-right: 10px solid;
  border-top: 0 solid transparent;
  content: "";
  height: 0;
  left: -10px;
  top: 10px;
  position: absolute;
  width: 0;
}
.friend-message .message::before {
  border-right-color: #fff;
}
.messages .message .time {
  font-size: 12px;
  line-height: 10px;
  margin-top: 0;
  text-align: right;
}
.messages .message {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  min-height: 40px;
  padding: 15px 20px;
  position: relative;
}
.my-message .profile-picture {
  float: right;
}
.my-message .message .messages .profile-picture img {
  border-radius: 50%;
  border: medium none;
}
.my-message .message {
  background-color: #007bff;
  color: #fff;
  margin-right: 50px;
}
.my-message .message::before {
  border-left-color: #007bff;
}
.my-message .message::before {
  border-bottom: 10px solid transparent;
  border-left: 10px solid;
  border-top: 0 solid transparent;
  border-left-color: #007bff;
  content: "";
  height: 0;
  position: absolute;
  top: 10px;
  right: -10px;
  width: 0;
}
.chat-form {
  background-color: #eff0f4;
  clear: both;
  margin-top: 15px;
  padding: 10px 0px 10px 10px;
}
.chat-form .form-group {
  width: 100%;
}
.chat-form .form-group textarea {
  width: 100%;
  padding: 5px 11px;
}
.chat-form .input-wrap {
  width: 100%;
  position: relative;
}
.chat-form .input-group-prepend {
  position: absolute;
  top: 7px;
  right: 6px;
}
.chat-form .input-group-prepend .input-group-text {
  -webkit-appearance: none;
  background: #007bff;
  color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 75px;
  display: block;
  border: none;
  line-height: 17px;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.chat-form .btn {
  padding: 15px 25px;
}
.chat-form {
  background-color: #dcdcdc;
  clear: both;
  margin-top: 15px;
  padding: 10px;
}
.message-history li .user-name .count-messages {
  float: right;
  margin: -13px;
  margin-right: 2px;
}

.large-text {
  font-size: 20px;
  color: #000;
  font-weight: lighter;
}
.larger-text {
  font-size: 24px;
  font-weight: lighter;
  text-transform: uppercase;
}
.witTxt {
  color: #fff !important;
}
.light-font {
  font-weight: lighter !important;
  font-size: 50px;
}
.black-bg {
  background: rgba(0, 0, 0, 0.63);
  color: #a8a8a8;
  text-transform: none;
}
.lft-list {
  padding: 10px;
  font-size: 18px;
  color: #fff;
}
.wit-border {
  border: 1px #fff solid;
  padding: 15px 60px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.rounded-bord {
  margin-right: 15px;
  border: 1px #fff solid;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}
#vertical-ticker {
  height: 120px;
  overflow: hidden;
}
#vertical-ticker li {
  padding: 15px 20px;
  display: block;
  text-align: center;
}
.to-bottom i.fa {
  font-size: 30px;
  border: 2px #a8a8a8 solid;
  padding: 20px 22px;
  border-radius: 50%;
  -webkit-animation: bounce 2s infinite linear;
  animation: bounce 2s infinite linear;
}
.slidertext1 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px !important;
  padding: 15px 0 !important;
  text-transform: none !important;
  font-family: "Montserrat", sans-serif;
  max-width: 550px;
  white-space: break-spaces;
}
.sliderpara {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0 !important;
  text-transform: none !important;
}
.slidertext5 {
  padding: 0 !important;
}
.slidertext5 a {
  color: #fff !important;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 50px;
  text-transform: capitalize !important;
  text-align: left;
  background: #2e499c;
  font-family: "Montserrat", sans-serif;
  border-radius: 45px;
}
/************************************************

           == How it works css==

*************************************************/
.howitwrap {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: -80px;
  margin-bottom: 50px;
}

.howitwrap .titleTop {
  text-align: center;
}
.howlist {
  list-style: none;
}
.howlist li {
  margin-top: 30px;
  text-align: center;
}
.howlist li .iconcircle {
  text-align: center;
  margin-bottom: 15px;
  display: inline-block;
}
.howlist li:nth-child(2) .iconcircle {
  border-color: #83ec0f;
}
.howlist li:last-child .iconcircle {
  border-color: #ffc456;
}
.howlist li .iconcircle i {
  font-size: 48px;
  color: #2e499c;
}
.howlist li h4 {
  font-size: 24px;
  font-weight: 300;
  color: #000;
  margin-bottom: 10px;
}
.howlist li p {
  font-size: 14px;
  line-height: 24px;
  color: #555;
}

.statsbox {
  padding-top: 30px;
}
.statsbox .statint {
  background-color: #f8fbff;
  padding: 20px;
  border-radius: 15px;
  text-decoration: none;
  margin-top: 30px;
  transition: all 0.4s ease 0s;
}
.statsbox .statint:hover {
  box-shadow: 0px 18px 40px rgb(25 15 9 / 10%);
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.statico {
  margin-right: 15px;
}
.statico i {
  width: 50px;
  height: 50px;
  background: #eaeff5;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 26px;
  color: #2e499c;
}
.statsbox .statint h4 {
  margin-bottom: 0;
  font-size: 32px;
}

.popularind {
  margin-top: 40px;
}
.popularind h4 {
  margin-bottom: 10px;
  font-size: 16px;
}

.hmindlist {
  margin-bottom: 0;
}
.hmindlist li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}
.hmindlist li a {
  background: #e7f1ff;
  color: #5e7ca1;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 25px;
  display: block;
}
.hmindlist li a:hover {
  color: #fff;
  background: #2e499c;
}

.citieswrap {
  background: #f2f6fd;
}
.citiessrchlist .cityinfobox {
  background: #fff;
  box-shadow: 0px 18px 40px rgb(25 15 9 / 10%);
  padding: 25px;
  border-radius: 0 0 10px 10px;
}
.citiessrchlist li {
  margin-top: 30px;
  transition: all 0.4s ease 0s;
}
.citiessrchlist li .cityimg img {
  border-radius: 10px 10px 0 0;
}

.citiessrchlist li:hover {
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.citiessrchlist li:hover .cityinfobox {
  box-shadow: 0px 18px 40px rgb(25 15 9 / 10%);
}

/************************************************
           == Top Employers css==
*************************************************/

.employerList:after {
  display: table;
  clear: both;
  content: "";
}

.employerList li .empint {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: 1px solid #eee;
  padding: 22px 18px;
  margin-bottom: 15px;
}
.employerList li .empint:hover {
  border-color: #2e499c;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.employerList li .empint a {
  color: #000;
}
.emptbox {
  display: flex;
  width: 100%;
}
.emptbox .comimg {
  width: 65px;
  padding-right: 15px;
}
.emptbox h4 {
  font-size: 16px;
  margin-bottom: 5px;
  min-height: 32px;
}
.text-info-right .emloc {
  color: #2e499c;
}
.cm-info-bottom {
  color: #8c96a3;
}

.employerList li h3 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.employerList li p {
  color: #888;
}
.employerList li img {
  width: 100%;
  border-radius: 8px;
}

.employerList .owl-stage-outer {
  padding: 20px 0 20px 0;
}
.employerList .owl-controls {
  text-align: center;
  margin-top: -15px;
}
.employerList .owl-controls .owl-nav i {
  display: inline-block;
  background: #333;
  color: #fff;
  font-size: 20px;
  padding: 9px 12px;
  margin: 0 5px;
  cursor: pointer;
}

.compnaieslist li .empint {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: 1px solid #eee;
  padding: 22px 18px;
  margin-bottom: 30px;
}
.compnaieslist li .empint:hover {
  border-color: #2e499c;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.compnaieslist li .empint a {
  color: #000;
}
.compnaieslist .emptbox {
  display: flex;
  width: 100%;
}
.compnaieslist .emptbox .comimg {
  width: 65px;
  padding-right: 15px;
}
.compnaieslist .emptbox h4 {
  font-size: 16px;
  margin-bottom: 5px;
  min-height: 32px;
}
.compnaieslist .text-info-right .emloc {
  color: #2e499c;
}
.compnaieslist .cm-info-bottom {
  color: #8c96a3;
}

.compnaieslist li h3 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.compnaieslist li p {
  color: #888;
}
.compnaieslist li img {
  width: 100%;
  border-radius: 8px;
}

/*
.compnaieslist li{margin-bottom: 30px;}
.compnaieslist li .compint{background: #fff; padding:10px; border: 1px solid #e4e4e4;}
.compnaieslist li .imgwrap{min-height: 240px; max-height: 240px; overflow: hidden; margin-bottom: 10px;}
.compnaieslist li .imgwrap img{width: 100%;}
.compnaieslist li:hover .compint{box-shadow: 0 0 10px rgba(0,0,0,0.1); border: 1px solid #2e499c;}
.compnaieslist li h3 a{font-size: 18px; color:#2e499c ;}
.compnaieslist li .loctext{margin-bottom: 10px;}
.compnaieslist li i {
    color: #2e499c;
    width: 18px;
    text-align: left;
}
.compnaieslist li .curentopen, .compnaieslist li .loctext{color: #666;}
*/

/*Top Search*/
.topsearchwrap h4 {
  margin: 0;
  color: #5d6469;
  font-size: 22px;
  font-weight: 500;
  padding: 15px 25px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background: #f6f6f6;
}
.srchbx {
  border: 1px solid #eee;
  margin-bottom: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.srchbx .srchint {
  padding: 30px;
  background: #fff;
}
.catelist li a {
  display: block;
  margin: 10px 0;
  position: relative;
  padding-left: 15px;
  color: #000;
  font-weight: 600;
}
.catelist li a:hover {
  color: #2e499c;
}
.catelist li a:before {
  position: absolute;
  left: 0;
  content: "\f0da";
  font-family: "FontAwesome";
  font-weight: 400;
}
.catelist li span {
  color: #6aca00;
}

/***************************
		Searh Tabs
***************************/
.tabswrap {
  border-bottom: 3px solid #2e499c;
}
.tabswrap .nav-tabs {
  border-bottom: none;
  float: right;
}
.tabswrap .nav-tabs > li {
  margin-bottom: 0;
}
.tabswrap .nav-tabs > li > a {
  background: none;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  padding: 15px 30px;
  border: none;
}

.tabswrap .nav-tabs > li > a.active,
.tabswrap .nav-tabs > li > a.active:focus {
  background: #2e499c;
  color: #fff;
  border-color: #2e499c;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
}
.tabswrap .nav-tabs > li.last {
  float: right;
}
.tabswrap .nav-tabs > li.last a {
  background: none;
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: none;
  margin-top: 7px;
}
.tabswrap .nav-tabs > li.last a:hover {
  border: none;
  color: #2e499c;
}

.largebanner {
  text-align: left;
  margin: 30px auto;
  max-width: 748px;
  position: relative;
  background: #fff;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
}
.largebanner img {
  display: block;
}
.shadow3,
.shadow4 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.1) inset;
  position: relative;
}
.shadow3:before,
.shadow3:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 2px;
  left: 15px;
  right: 15px;
  z-index: -1;
  border-radius: 100px/30px;
  -webkit-box-shadow: 0 0 20px 2px #ccc;
  -moz-box-shadow: 0 0 20px 2px #ccc;
  box-shadow: 0 0 20px 2px #ccc;
}

/************************************************

           == Home Jobs css==

*************************************************/

.jobslist {
  list-style: none;
}
.jobslist li {
  margin-top: 30px;
}
.jobslist li .jobint {
  background: #fff;
  padding: 15px;
  transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  min-height: 113px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #fff;
}
.jobslist li .jobint:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-color: #2e499c;
}
.jobslist li .col-md-7,
.jobslist li .col-md-9 {
  padding-left: 0;
}
.jobslist li h4 {
  margin-bottom: 5px;
}
.jobslist li h4 a {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.jobslist li h4 a:hover {
  color: #2e499c;
}
.jobslist li .company {
  color: #999;
  padding: 7px 0;
}
.jobslist li .company a {
  color: #2e499c;
}
.jobslist li .jobloc {
  color: #000;
}
.jobslist li .jobloc span {
  color: #999;
}
.jobslist li .applybtn {
  background: #fff;
  display: inline-block;
  color: #2e499c;
  font-weight: 600;
  padding: 9px 13px;
  margin-top: 20px;
  border: 1px solid #2e499c;
  border-radius: 45px;
}
.jobslist li .applybtn:hover {
  background: #2e499c;
  color: #fff;
  text-decoration: none;
}

.newjbox.row {
  margin: 0 -1px;
}
.newjbox.row .col-md-4 {
  padding: 0 1px;
}
.newjbox li {
  margin-top: 2px;
}
.newjbox li .jobint {
  box-shadow: none;
  border-radius: 0;
  background: #f9fafb;
  border-bottom-color: #f2f9ff;
}
.newjbox.row {
  margin-top: 20px;
}
.newjbox.row li h4 a {
  font-weight: 400;
}

.viewallbtn {
  text-align: center;
  margin-top: 40px;
}
.viewallbtn a {
  display: inline-block;
  background: #2e499c;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 5px;
}
.viewallbtn a:hover {
  background: #333;
  text-decoration: none;
}
.partTime {
  background: #f8a60c;
  color: #fff;
  font-size: 13px;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 3px;
}
.fulltime {
  background: #6aca00;
  color: #fff;
  font-size: 13px;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 3px;
}
label.fulltime[title="Part Time"] {
  background: #f8a60c;
}
label.fulltime[title="Freelance"] {
  background: #ff0000;
}
label.fulltime[title="Contract"] {
  background: #0c86f8;
}
label.fulltime[title="Internship"] {
  background: #9b54f4;
}
label.fulltime[title="First Shift (Day)"] {
  background: #afcb59;
}
label.partTime[title="First Shift (Day)"] {
  background: #afcb59;
}
label.partTime[title="Second Shift (Afternoon)"] {
  background: #688cd5;
}
label.partTime[title="Third Shift (Night)"] {
  background: #ef7d7d;
}
label.partTime[title="Rotating"] {
  background: #b09eeb;
}
.freelance {
  background: #ff0000;
  color: #fff;
  font-size: 13px;
  display: inline-block;
  padding: 4px 6px;
}

.featuredlist {
  list-style: none;
}
.featuredlist .jobint {
  background-color: #fff;
  padding: 30px;
  border-radius: 25px;
  text-decoration: none;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  transition: all 0.4s ease 0s;
}
.featuredlist .jobint:hover {
  border-color: #2e499c;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0px 0 30px rgba(81, 81, 102, 0.2);
}
.fticon i {
  background-color: #d9e4ed;
  border-radius: 10px;
  padding: 8px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  margin-right: 10px;
  text-decoration: none;
}

.featuredlist .jobint h4 {
  margin: 0;
  padding: 25px 0;
}
.featuredlist .jobint h4 a {
  font-size: 18px;
  color: #000;
}
.featuredlist .jobint h4 a:hover {
  color: #2e499c;
}
.featuredlist .jobint strong {
  font-weight: 700;
}
.featuredlist .jobint strong i {
  color: #2e499c;
}
.featuredlist .jobint span {
  color: #999;
}

.jobcompany {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.jobcompany .company-logo {
  display: block;
  width: 70px;
  height: 70px;

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}
.jobcompany .company-logo img {
  border-radius: 15px;
}
.ftjobcomp span {
  display: block;
  color: #999;
  margin-bottom: 10px;
}
.ftjobcomp a {
  color: #000;
  font-weight: 600;
}

/************************************************

           == Video css==

*************************************************/

.videowraper {
  position: relative;
  padding: 60px 0;
}

.videowraper .titleTop {
  text-align: left;
  margin-top: 60px;
}
.vidover {
  padding: 30px;
}

.videowraper p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  color: #555;
}

.videowraper .embed-responsive {
  background: #fff;
  border-radius: 30px;
  border: 20px solid #fff;
  box-shadow: 3px 8px 30px rgba(0, 0, 0, 0.2);
}
.videowraper .embed-responsive iframe {
  width: 100%;
  height: 400px;
}

/************************************************
           == Loginbox css==
*************************************************/
/* .userloginbox{background:url(../images/login-bg.jpg) no-repeat center; background-attachment: fixed; background-size: cover; padding:40px 0 120px 0; text-align: center;} */
.userloginbox .viewallbtn {
  margin-top: 30px;
}
.userloginbox .viewallbtn a {
  background: #2e499c;
}
.userloginbox .viewallbtn a:hover {
  background: #333;
}
.userloginbox p {
  max-width: 770px;
  margin: 0 auto;
}

/* .emploginbox{background:url(../images/login-bg2.jpg) no-repeat center; background-attachment: fixed; background-size: cover; padding:50px 0; text-align: center;} */
.emploginbox .viewallbtn {
  margin-top: 30px;
}
.emploginbox .viewallbtn a {
  background: #2e499c;
}
.emploginbox .viewallbtn a:hover {
  background: #fff;
  color: #2e499c;
}
.emploginbox p {
  max-width: 770px;
  margin: 0 auto;
}

/************************************************
           == Testimonials css==
*************************************************/
/* .testimonialwrap{background:url(../images/testimonials-bg.jpg) no-repeat center; background-attachment: fixed; background-size: cover; text-align: center;} */
.testimonialwrap .titleTop h3 {
  color: #fff;
}

.testimonialsList {
  list-style: none;
  margin-top: 40px;
}
.testimonialsList li {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: left;
}
.testimonialsList li .ratinguser {
  color: #eba102;
  font-size: 18px;
}
.testimonialsList li .testimg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.testimonialsList li .clientname {
  font-size: 24px;
  font-weight: 700;
  padding: 15px 0 5px 0;
  color: #333;
  font-family: "Montserrat", sans-serif;
}
.testimonialsList li p {
  font-style: italic;
  line-height: 24px;
  font-size: 16px;
  color: #333;
}
.testimonialsList li .clientinfo {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2e499c;
  margin-bottom: 15px;
}
/************************************************

           == App Box css==

*************************************************/

.appwraper {
  /* background: url(../images/app-bg.jpg) no-repeat top; */
  background-size: cover;
  padding-top: 70px;
  background-attachment: fixed;
}
.appimg {
  text-align: center;
}
.appimg img {
  display: block;
}
.appwraper .titleTop {
  text-align: left;
  margin-bottom: 10px;
}
.appwraper .subtitle {
  color: #fff;
}
.appwraper .titleTop h3 {
  color: #fff;
  text-transform: uppercase;
  margin-top: 5px;
}
.appwraper .subtitle2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #fff;
}
.appwraper p {
  color: #fff;
  line-height: 24px;
}
.appwraper .appbtn {
  margin-top: 45px;
  margin-bottom: 30px;
}
.appwraper .appbtn a {
  display: inline-block;
  margin-right: 10px;
}
/*Page Title*/

.pageTitle {
  background: url(../img/image/page-title-bg.jpg) no-repeat;
  background-size: cover;
  padding: 15px 0;
}
.pageTitle h1 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}
.breadCrumb {
  text-align: right;
  color: #fff;
  margin-top: 5px;
}
.breadCrumb a {
  color: #fff;
  display: inline-block;
  padding: 0 5px;
}
.breadCrumb span {
  color: #2e499c;
  display: inline-block;
  padding-left: 5px;
}
/************************************************

           == Search List css==

*************************************************/

.listpgWraper {
  padding: 40px 0;
  min-height: 400px;
}
.pageSearch {
  padding: 50px;
  background: #f2f6fd url(../img/image/common-head.jpg) no-repeat center;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  margin-top: 30px;
}
.pageSearch .searchform {
  margin: 0 auto;
}
.pageSearch a.btn {
  background: #2e499c;
  color: #fff;
  padding: 13px 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  display: block;
}
.pageSearch a.btn i {
  font-weight: 400;
  font-size: 16px;
  margin-right: 5px;
}
.pageSearch a.btn:hover {
  background: #666;
}

.searchform {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 10%);
}

.searchform .form-control {
  height: auto;
  padding: 13px 12px;
  font-size: 16px;
  border: none;
}
.searchform select.form-control {
  padding: 10px 12px;
  background: #fff;
  line-height: 24px;
  appearance: auto;
  border-left: 1px solid #eee;
}
.searchform .btn {
  background: #2e499c;
  font-size: 16px;
  color: #fff;
  padding: 14px 20px;
  border-radius: 8px !important;
  margin-left: 10px !important;
}
.searchform .btn:hover {
  background: #444;
}
/************************************************

           == Sidebar css==

*************************************************/
.jobreqbtn {
  margin-bottom: 20px;
}
.jobreqbtn a {
  display: block;
  padding: 10px 20px;
  text-align: left;
  margin-bottom: 10px;
  background: #2e499c;
  color: #fff;
}
.jobreqbtn a:hover {
  background: #333;
  color: #fff;
}
.jobreqbtn a.btn-job-alert-disabled {
  color: #999 !important;
  border: 1px solid #999;
  cursor: default;
  background: none;
}
.jobreqbtn a.btn-job-alert {
  background: #2e499c;
  color: #fff;
}

.sidebar {
  background: #f2f6fd;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.sidebar .widget {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}
.sidebar .widget-title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}
.optionlist {
  list-style: none;
}
.optionlist li {
  margin-bottom: 10px;
  padding: 5px 0;
  position: relative;
  padding-left: 30px;
}
.optionlist li span {
  position: absolute;
  right: 0;
  top: 6px;
}
.optionlist li input[type="checkbox"] {
  display: none;
}
.optionlist li input[type="checkbox"] + label {
  width: 20px;
  height: 20px;
  border: 1px solid #d1d1d1;
  background: #fff;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
.optionlist li input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #4ece00;
  padding-left: 2px;
  position: relative;
  top: 2px;
}
.sidebar a {
  color: #2e499c;
  display: inline-block;
  margin-top: 10px;
}
.searchnt .btn {
  background: #2e499c;
  display: block;
  border-radius: 0;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 15px;
}
.sidebar .gad {
  margin-top: 15px;
  text-align: center;
}
.social a {
  color: #00a8ff;
  font-size: 32px;
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}
.social a:hover {
  color: #000;
}
/*Listing*/
.topstatinfo {
  margin-bottom: 30px;
}
.searchList {
  list-style: none;
  margin-bottom: 30px;
}
.searchList > li {
  background: #fcfdff;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: 1px solid #eee;
  padding: 22px 18px;
  margin-bottom: 15px;
}
.searchList > li.featured {
  background: #f7f5ff;
  border-color: #ddd;
}

.searchList > li:hover {
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
  border-bottom-color: #2e499c;
}
.searchList > li .jobimg {
  float: left;
  width: 70px;
  margin-right: 15px;
}
.searchList > li .jobimg img {
  border-radius: 10px;
}
.searchList > li h3 i {
  font-size: 16px;
  color: rgb(231, 1, 1);
}
.searchList > li h3 a {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.searchList li .companyName {
  margin: 10px 0;
  color: #969595;
}
.searchList li .companyName a {
  color: #969595;
}
.searchList li a:hover {
  color: #333;
}
.searchList li .location {
  color: #000;
}
.searchList li .location span {
  color: #333;
}
.searchList li .listbtn {
  margin-top: 20px;
}
.searchList li .listbtn a {
  background: #fff;
  display: block;
  border-radius: 40px;
  width: 100%;
  color: #2e499c;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 15px;
  text-align: center;
  border: 1px solid #2e499c;
}
.searchList li .listbtn a:hover {
  background: #2e499c;
  color: #fff;
  text-decoration: none;
}
.searchList li p {
  line-height: 22px;
  color: #333;
  margin: 10px 0 0 0;
}
.searchList li .cateinfo {
  color: #2e499c;
  margin: 10px 0;
}
.searchList li .minsalary {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 25px;
  color: #2e499c;
}
.searchList li .minsalary span {
  color: #999;
  font-weight: 400;
}
/*Pagination*/

.pagiWrap .showreslt {
  font-weight: 600;
  margin-top: 10px;
}
.pagiWrap .pagination {
  text-align: right;
  margin: 0;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0;
}

.btn-bars {
  background: #eee;
  padding: 10px 20px;
}
.btn-bars::after {
  display: none;
}
.btn-bars:hover {
  background-color: #ddd;
}

.btn-primary {
  background-color: #2e499c;
  line-height: normal;
  padding: 10px 25px;
}

.searchList > li .dropdown-menu {
  z-index: 10;
}
.searchList > li .dropdown-menu li a {
  padding: 10px 15px;
}

/************************************************

           == Job Detail css==

*************************************************/

.job-header {
  background: #fff;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0px 20px 25px rgb(0, 0, 0, 0.05);
  border: 1px solid #eee;
}
.job-header.nomrg {
  margin-bottom: 0;
}
.job-header .jobinfo {
  padding: 20px;
}

.jobPagetitle .jobinfo {
  padding: 0 0 30px 0;
}
.jobPagetitle .jobinfo h2 {
  font-size: 24px;
  color: #444;
}
.jobmainreq {
  border-radius: 0 0 5px 5px;
}

.jobPagetitle .jobinfo .ptext {
  color: #999;
  margin: 12px 0;
}
.jobPagetitle .jobinfo .salary {
  font-size: 16px;
  font-weight: 700;
  color: #888;
}
.jobPagetitle .jobinfo .salary strong {
  color: #000;
}
.companyinfo {
  margin-bottom: 30px;
  background: #f9f9ff;
  padding: 30px;
  border-radius: 15px;
}
.companyinfo h3 {
  margin-bottom: 30px;
  color: #2e499c;
  font-size: 24px;
}
.companyinfo .companylogo {
  width: 100px;
  background: #fff;
  display: inline-block;
  margin-bottom: 10px;
  float: left;
  margin-right: 15px;
}
.companyinfo .companylogo img {
  display: block;
  border-radius: 15px;
}
.companyinfo .ptext {
  color: #2e499c;
  padding: 7px 0;
}
.companyinfo .title a {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.companyinfo .opening a {
  color: #2e499c;
}
.companyoverview {
  text-align: left;
}
.companyoverview p {
  color: #555;
  margin-bottom: 0;
}

/*Candidate Info*/

.job-header .jobinfo .candidateinfo .userPic {
  float: left;
  width: 100px;
  height: 100px;
  background: #cbcbcb;
  margin-right: 20px;
  border: 1px solid #b1bcc5;
}

.loctext {
  margin-top: 15px;
}
.loctext i {
  margin-right: 5px;
  width: 24px;
  color: #2e499c;
}
.loctext a {
  color: #333;
}

.job-header .jobinfo .candidateinfo .title {
  font-size: 24px;
  color: #333;
  font-weight: 600;
}
.job-header .jobinfo .candidateinfo .desi {
  font-size: 18px;
  color: #666;
  margin-top: 10px;
}
.job-header .jobinfo .candidateinfo .loctext {
  line-height: 24px;
  color: #555;
  margin-top: 8px;
}
.job-header .jobinfo .candidateinfo .loctext i {
  width: 20px;
  color: #2e499c;
}

.container {
  max-width: 1350px;
}

.usercoverimg {
  position: relative;
  min-height: 240px;
  background: #333;
}
.usercoverimg:after {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9026961126247374) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9026961126247374) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9026961126247374) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  content: "";
}

.userMaininfo {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  z-index: 1;
}
.userMaininfo .userPic {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border: 5px solid #fff;
  position: relative;
  z-index: 15;
  margin-left: 40px;
  float: left;
  margin-right: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background: #eee;
}
.userMaininfo .userPic img {
  display: block;
}

.userMaininfo h3 {
  color: #fff;
  padding-top: 35px;
  margin-bottom: 5px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
}
.userMaininfo h3 span {
  font-size: 14px;
  color: #00ff53;
}
.userMaininfo .desi {
  font-size: 14px;
  color: #fff;
}

.membersinc {
  float: right;
  color: #fff;
  font-size: 14px;
  margin: -10px 20px 10px 0;
}
.userlinkstp {
  padding: 20px 25px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  border-radius: 0 0 5px 5px;
  min-height: 82px;
}
.userlinkstp.applybox {
  text-align: center;
  border-top: none;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}
.userlinkstp a {
  margin-left: 10px;
  color: #2e499c;
  font-weight: 600;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 40px;
  border: 1px solid #2e499c;
  position: relative;
  z-index: 2;
}
.userlinkstp a.report {
  color: #e01010;
  border-color: #e01010;
}
.userlinkstp a.apply {
  background: #2e499c;
  color: #fff;
  font-size: 18px;
}
.userlinkstp .jbexpire {
  background: #ff7c69;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}
.userlinkstp a:hover {
  background: #2e499c;
  color: #fff;
}
.userlinkstp a.report:hover {
  color: #fff;
  background: #e01010;
}

.prolinkadd {
  display: inline-block;
  background: #2e499c;
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  margin-top: 15px;
}
.prolinkadd:hover {
  background: #222;
  color: #fff;
  text-decoration: none;
}
.cadsocial {
  margin-top: 10px;
}
.cadsocial a {
  color: #666;
  font-size: 24px;
  display: inline-block;
  margin-right: 7px;
}
.cadsocial a:hover {
  color: #2e499c;
}
.relatedJobs {
  margin-bottom: 30px;
}
.relatedJobs h3 {
  font-size: 24px;
  font-weight: 700;
  color: #555;
  margin-bottom: 15px;
}
.relatedJobs .searchList li {
  margin-bottom: 15;
}
.relatedJobs .searchList li h3 {
  margin-bottom: 0;
}
.relatedJobs .searchList li .location {
  margin-top: 5px;
}
.relatedJobs .searchList li .companyName {
  margin-bottom: 0;
  margin-top: 5px;
}

/*Buttons*/

.jobButtons {
  padding: 20px 25px;
}
.jobButtons.applybox {
  text-align: right;
  border-top: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  margin-top: 15px;
}
.jobButtons a {
  margin-right: 10px;
  color: #2e499c;
  font-weight: 600;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 40px;
  border: 1px solid #2e499c;
}
.jobButtons a.report {
  color: #e01010;
  border-color: #e01010;
}
.jobButtons a.apply {
  background: #2e499c;
  color: #fff;
  font-size: 18px;
  margin-right: 0;
}
.jobButtons .jbexpire {
  background: #ff7c69;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}
.jobButtons a:hover {
  background: #2e499c;
  color: #fff;
  border-color: #000;
}
.jobButtons a.report:hover {
  color: #fff;
  background: #e01010;
}

.job-header .contentbox {
  padding: 35px;
}
.job-header .contentbox h3 {
  font-size: 24px;
  font-weight: 700;
  color: #2e499c;
  margin-bottom: 10px;
}
.job-header .contentbox h3 i,
.jobdetail h3 i,
.companyinfo h3 i {
  color: #ccc;
  margin-right: 5px;
  font-size: 20px;
}
.job-header .contentbox p {
  line-height: 24px;
  color: #555;
  margin-top: 20px;
  margin-bottom: 0;
}
.job-header .contentbox p strong {
  font-weight: 700;
}
.job-header .contentbox p em {
  font-style: italic;
}
.job-header .contentbox p u {
  text-decoration: underline;
}

.jobButtons a.apply.applied {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}
.job-header .contentbox iframe {
  width: 100%;
  height: 350px;
}

.job-header .contentbox ul {
  margin-bottom: 30px;
}
.job-header .contentbox ul li {
  padding: 5px 0 5px 25px;
  position: relative;
  line-height: 24px;
}
.job-header .contentbox ul li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #2e499c;
  position: absolute;
  left: 0;
}

.job-header .contentbox ul.userPortfolio {
  margin-bottom: 0;
}

.benefits .contentbox ul {
  margin-bottom: 0;
}
.benefits p:empty {
  display: none;
}

ul.skillslist {
  margin-bottom: 0 !important;
}
ul.skillslist li:before {
  display: none;
}
ul.skillslist li {
  display: inline-block;
  padding: 0 !important;
}
ul.skillslist li a {
  display: inline-block;
  background: #eee;
  color: #555;
  padding: 10px 20px;
  margin: 5px 10px 5px 0;
  border-radius: 45px;
  font-weight: 600;
  font-size: 14px;
}
ul.skillslist li a:hover {
  text-decoration: none;
  background: #2e499c;
  color: #fff;
}

/*Job Info*/
.jobdetail .table-bordered {
  border: none;
  margin-bottom: 0;
}
.jobdetail .table-bordered td {
  border: none;
  border-top: 1px solid #ddd;
}
.jobdetail {
  padding: 35px;
}
.jobdetail iframe {
  width: 100%;
  height: 250px;
  border: none;
}
.jobdetail h3 {
  font-size: 18px;
  color: #2e499c;
  font-weight: 700;
}
.jbdetail {
  margin-top: 20px;
}
.jbdetail li {
  margin-bottom: 20px;
  color: #908f8f;
}
.jbdetail li a {
  display: block;
  color: #2e499c;
  font-weight: 600;
}
.jbdetail li span {
  display: block;
  color: #000;
  font-weight: 600;
}
.jbdetail li span.permanent {
  color: #2e499c;
}
.jbdetail li span.contract {
  color: #2e499c;
}
.jbdetail li span.freelance {
  color: #f07200;
  background: none;
  padding: 0;
}
/*Skills*/

.skillswrap {
  margin-top: 20px;
}
.skillswrap h5 {
  color: #7b7b7b;
  font-weight: 400;
  margin-bottom: 5px;
}
/*Education*/

.educationList {
  margin-bottom: 0 !important;
}
.educationList li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  margin-top: 30px;
}
.educationList li:hover {
  border-bottom-color: #2e499c;
}
.educationList li:before {
  display: none;
}
.educationList li:last-child {
  border-bottom: 0;
  padding-bottom: 0 !important;
}
.educationList li .date {
  font-weight: 700;
  line-height: 18px;
  color: #2e499c;
}
.educationList li h4 {
  font-size: 18px;
  font-weight: 700;
  color: #58c0ff;
  margin-bottom: 5px;
}
.educationList li h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.educationList li p {
  margin-top: 0 !important;
}
/*Experience*/

.experienceList {
  margin-bottom: 0 !important;
}
.experienceList li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-top: 30px;
}
.experienceList li:hover {
  border-bottom-color: #2e499c;
}
.experienceList li:before {
  display: none;
}
.experienceList li:last-child {
  border-bottom: 0;
}
.experienceList li h4 {
  font-size: 18px;
  font-weight: 700;
  color: #58c0ff;
  margin-bottom: 10px;
}
.experienceList li p {
  margin-bottom: 0 !important;
  margin-top: 10px;
}
/*Portfolio*/

.userPortfolio li {
  padding: 0 15px !important;
  margin-top: 30px;
}
.userPortfolio li:before {
  display: none;
}
.userPortfolio li .imgbox {
  overflow: hidden;
  position: relative;
}
.userPortfolio li .itemHover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(22, 34, 46, 0.9);
  transform: scale(0);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.userPortfolio li:hover .itemHover {
  transform: scale(1);
}
.userPortfolio li .zoombox {
  display: table;
  width: 100%;
  height: 20%;
  text-align: right;
}
.userPortfolio li .item-zoom {
  display: inline-block;
  border: 2px solid #fff;
  padding: 6px 12px;
  margin: 15px 15px 0 0;
  color: #fff;
}
.userPortfolio li .item-zoom:hover {
  background: #2079f2;
}
.userPortfolio li .infoItem {
  display: table;
  width: 100%;
  height: 70%;
  text-align: center;
}
.userPortfolio li .itemtitle {
  vertical-align: bottom;
  padding: 0 15px 15px;
  position: relative;
  display: table-cell;
}
.userPortfolio li .itemtitle h5 {
  color: #fff;
  font-size: 18px;
  margin: 0 0 5px;
}
.userPortfolio li .itemtitle p {
  color: #ccc;
  line-height: 18px;
}
.userPortfolio li .itemtitle a {
  color: #2079f2;
}
.userPortfolio li.shown,
.no-cssanimations .userPortfolio li,
.no-js .grid li {
  opacity: 1;
}
.userPortfolio li a,
.userPortfolio li img {
  outline: 0;
  border: none;
  display: block;
  max-width: 100%;
}

.ptsklbx {
  padding: 35px 35px 35px 35px;
  border-top: 1px solid #eee;
}

.profileskills {
  list-style: none;
  margin: 0 -5px;
}
.profileskills li {
  padding: 0 5px;
  margin-bottom: 10px;
}
.profileskills li .skillbox {
  border: 1px solid #eee;
  padding: 15px;
  font-size: 14px;
  font-weight: 700;
}
.profileskills li .skillbox:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-color: #28a745;
}
.profileskills li .skillbox span {
  display: block;
  margin-top: 5px;
  font-weight: 400;
}

/************************************************

           == Cms Pages css==

*************************************************/

.inner-page {
  padding: 50px 0;
}
.inner-page .title {
  text-align: center;
  margin-bottom: 40px;
}
.inner-page .title > span {
  color: #292929;
  font-size: 17px;
  margin-bottom: 10px;
}
.inner-page .title h2 {
  color: #292929;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 0;
}
.inner-page .title > p {
  line-height: 24px;
}
.contact {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 30px;
}
.contact > span {
  color: #2e499c;
  float: left;
  font-size: 50px;
  line-height: 30px;
  margin-right: 10px;
  text-align: center;
  width: 70px;
}
.inner-page .fa-home:before {
  content: "\f015";
}
.information {
  overflow: hidden;
  padding-right: 20px;
  margin-top: 5px;
}
.information > strong {
  color: #000;
  display: table;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.information > p {
  line-height: 22px;
  margin: 0;
}
.contact-form input,
.contact-form textarea {
  color: #8c8c8c;
  border: 1px solid #ddd;
  font-size: 12px;
  line-height: 32px;
  margin-bottom: 15px;
  padding: 10px 20px;
  width: 100%;
}
.contact-form {
  text-align: right;
}
.contact-form button {
  background-color: #2e499c;
  border: 0 none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 18px 40px;
  margin-top: 20px;
}
/************************************************

           == FAQs css==

*************************************************/

.faqs {
  margin-bottom: 30px;
}
.faqs .panel-default > .panel-heading {
  background: none;
  border: none;
  padding: 0;
}
.faqs h4 a {
  padding: 15px 55px 15px 15px;
  display: block;
  background: #2e499c;
  color: #fff;
  font-size: 16px;
  position: relative;
}
.panel-group .panel {
  border-radius: 0;
  margin-bottom: 20px;
}
.panel-group .panel-body {
  line-height: 24px;
  color: #555;
}
.faqs h4 .collapsed:hover,
.faqs h4 a:hover,
.faqs h4 a:active {
  text-decoration: none;
  background: #2e499c;
  color: #fff;
}
.faqs h4 a:before {
  content: "\f068";
  background: #fff;
  font-family: "FontAwesome";
  width: 30px;
  padding: 6px 0;
  color: #2e499c;
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
}
.faqs h4 .collapsed:before {
  content: "\f067";
  font-family: "FontAwesome";
  background: #666;
  width: 30px;
  padding: 6px 0;
  color: #fff;
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  text-decoration: none;
}
.faqs h4 a.collapsed {
  background: #fff;
  color: #666;
  text-decoration: none;
}
/*Form Panel*/

.formpanel {
  margin-top: 20px;
}
.formpanel .formrow {
  margin-bottom: 15px;
}
.formpanel .formrow > label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #999;
}
.formpanel .form-control {
  height: auto;
  border-radius: 0;
  padding: 10px 13px;
  border-color: #ddd;
}
.formpanel select.form-control {
  padding: 10px 13px;
  border-radius: 0;
  line-height: normal;
  appearance: auto;
}
.formpanel textarea.form-control {
  height: 150px;
}
.formpanel .formrow > label.btn {
  color: #fff;
}
.formpanel .btn {
  width: 100%;
  background: #2e499c;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.formpanel .btn:hover {
  background: #222;
}

.formpanel.mt0 {
  margin-top: 0;
}

#projects_div h3 {
  font-size: 16px;
  margin-top: 5px;
}

.featchoose li {
  margin-bottom: 10px;
}
/*Google Map*/

.gmap {
  margin-top: 20px;
}
.gmap iframe {
  height: 250px;
  width: 100%;
  border: none;
}
.googlemap iframe {
  height: 400px;
  width: 100%;
  border: none;
}
/************************************************

           == User Account css==

*************************************************/

.authpages {
  padding: 100px 0;
  position: relative;
}
/* .authpages:before{background: url(../images/search-bg.png) no-repeat bottom; position: absolute; top: 0; left: 0; width: 100%; height: 550px; content: '';} */

.useraccountwrap {
  max-width: 600px;
  margin: 0 auto;
}
.userccount {
  background: #f5f7ff;
  padding: 50px;
  border-radius: 10px;
  border-bottom: 3px solid #2e499c;
  margin-bottom: 30px;
  box-shadow: 0px 6px 12px rgb(0 0 0 / 5%);
}
.whitebg {
  background: #fff;
}
.userccount p {
  text-align: center;
  line-height: 24px;
  color: #999;
}
.userccount h5 {
  font-size: 20px;
  color: #555;
  font-weight: 700;
  margin-bottom: 15px;
}
.socialLogin {
  margin-bottom: 20px;
  text-align: center;
  padding-bottom: 20px;
}
.socialLogin a {
  display: inline-block;
  width: 36px;
  height: 36px;
  color: #fff;
  font-size: 18px;
  margin: 0 5px;
  line-height: 36px;
}
.socialLogin a.fb {
  background: #3b5998;
}
.socialLogin a.gp {
  background: #dd4b39;
}
.socialLogin a.tw {
  background: #00aced;
}
.newuser {
  margin-top: 30px;
  text-align: center;
  color: #666;
  font-size: 18px;
}
.newuser a {
  font-weight: 600;
}
.userbtns .nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
}
.userbtns .nav-tabs > li {
  width: 50%;
  margin-bottom: 0;
}
.userbtns .nav-tabs > li a {
  border: none;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #222;
  padding: 15px 30px;
}
.userbtns .nav-tabs > li a:hover,
.userbtns .nav-tabs > li a.active {
  background: #2e499c;
  border: none;
  color: #fff;
}
.userbtns .nav-tabs > li.active a:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #2e499c;
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}

.strippckinfo {
  background: #fff;
  padding-top: 20px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.pkginfo {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  color: #777;
}
.pkginfo strong {
  color: #2e499c;
  font-weight: 700;
}

.tab-content > .active {
  opacity: 1;
}
.tab-content > .active.show {
  opacity: 1;
}

.profileban {
  position: relative;
}
.abtuser {
  background: #f1f5ff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.profileban img {
  width: 100%;
}
.abtuser h4 {
  color: #2166a4;
  font-size: 30px;
  font-weight: 700;
}
.abtuser h6 {
  color: #444;
  font-size: 16px;
  font-weight: 600;
}

.uavatar img {
  display: block;
}
.editbtbn {
  text-align: right;
  margin-top: 10px;
}
.editbtbn a {
  background: #e83f6f;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  display: inline-block;
  font-weight: 700;
}
.editbtbn a:hover {
  background: #2166a4;
  color: #fff;
  text-decoration: none;
}

.userdata {
  margin-top: 10px;
}
.userdata li {
  color: #737373;
  font-size: 16px;
}
.userdata li i {
  color: #e83f6f;
  font-size: 18px;
}

/***************************
		404 Page
***************************/

.error-page-wrap {
  padding: 100px 0;
}
.error-page-wrap .errormain {
  text-align: center;
}
.error-page-wrap .errormain h2 {
  font-size: 350px;
  color: #999;
  font-weight: 900;
  line-height: 350px;
}
.error-page-wrap .errormain h3 {
  font-size: 40px;
  color: #888;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 5px;
  display: inline-block;
  border: 5px solid #ddd;
  padding: 20px 40px;
  margin-top: -20px;
}
.error-msg {
  margin-top: 50px;
}
.error-msg p {
  max-width: 500px;
  margin: auto;
  line-height: 24px;
  font-size: 16px;
  color: #666;
}
.error-msg .btn {
  background: #2e499c;
  color: #fff;
  margin-top: 30px;
  border-radius: 0;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 600;
}
/***************************

	About Us

****************************/

.about-wraper {
  padding: 100px 0;
}
.about-wraper h2 {
  font-size: 36px;
  color: #2e499c;
  font-weight: 700;
  margin-bottom: 15px;
}
.about-wraper p {
  line-height: 24px;
  font-size: 16px;
}

.about-wraper strong {
  font-weight: 700;
}
.about-wraper em {
  font-style: italic;
}
.about-wraper u {
  text-decoration: underline;
}
.about-wraper ul {
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-wraper ul li {
  padding: 5px 0 5px 25px;
  position: relative;
  line-height: 24px;
  font-size: 16px;
}
.about-wraper ul li:before {
  content: "\f18e";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
}

.what_we_do {
  padding: 50px 0;
  background: #f8f8f8;
  margin-top: 100px;
}
.what_we_do .main-heading {
  color: #2e499c;
  text-align: center;
  line-height: 48px;
  font-size: 36px;
  font-weight: 700;
}
.whatText {
  font-size: 24px;
  text-align: center;
  color: #444;
  line-height: 42px;
}
.whatList {
  list-style: none;
  margin-top: 50px;
}
.whatList li {
  text-align: center;
}
.whatList li .iconWrap {
  display: inline-block;
  border: 4px solid #ddd;
  border-radius: 100%;
  padding: 10px;
}
.whatList li .icon {
  font-size: 103px;
  color: #fff;
  background: #2e499c;
  width: 180px;
  height: 180px;
  text-align: center;
  line-height: 180px;
  border-radius: 100%;
}
.whatList li h3 {
  font-size: 30px;
  color: #333;
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 600;
}
.whatList li p {
}
.textrow {
  padding-top: 100px;
}
ul.orderlist {
  list-style: none;
  margin-top: 30px;
}
ul.orderlist li {
  float: left;
  width: 48%;
  margin-bottom: 12px;
  color: #333;
  font-size: 16px;
}
ul.orderlist li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #82b440;
}
/**************************

	Pay Packages

**************************/

/*************************

	Three colum Packages

*************************/

.four-plan h3 {
  padding: 0px;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}
.four-plan .boxes {
  list-style: none;
  border-radius: 5px;
  background: #f6f6f6;
  padding: 10px 20px;
  text-align: center;
  margin-top: 30px;
  border-top: 5px solid #2e499c;
}
.four-plan .boxes:hover {
  box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 20%);
}
.four-plan .boxes li {
  display: block;
}
.four-plan .icon {
  font-size: 52px;
  color: #565656;
  padding: 30px 0;
}
.four-plan .plan-name {
  font-size: 26px;
  font-weight: 600;
  padding: 10px 0;
}
.four-plan .plan-date {
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
}
.plan-pages {
  font-size: 16px;
  line-height: 43px;
  font-weight: bold;
  color: #868686;
}
.plan-pages1 {
  display: block;
  font-size: 16px;
  line-height: 43px;
  font-weight: bold;
  color: #e2e2e2;
}
.order {
  font-size: 16px;
  padding: 10px 0px;
  font-weight: bold;
}
.order a {
  background: #2e499c;
  border-radius: 5px;
  padding: 12px 20px;
  color: #fff;
  text-decoration: none;
  display: block;
}
.order.paypal a {
  background: #003087;
}
.order.payu a {
  background: #54d726;
}
.order a:hover {
  background: #333;
}

.invitereval {
  padding: 30px;
  text-align: center;
}
.invitereval h3 {
  font-size: 24px;
}
.totalpay {
  font-size: 18px;
  padding: 15px 0;
}
.totalpay strong {
  color: #2e499c;
  font-weight: 700;
}

.btn2s li {
  display: inline-block;
}

.main-plan {
  margin-bottom: 15px;
  text-align: center;
}
.main-plan .plan-price1-1 {
  font-size: 20px;
  color: #565656;
  display: inline-block;
  padding-top: 22px;
  font-weight: 300;
}
.main-plan .plan-price1-2 {
  font-size: 36px;
  font-weight: bold;
  display: inline-block;
  color: #2e499c;
}

.four-plan .col-md-4:nth-child(2) .main-plan .plan-price1-2 {
  color: #2e499c;
}
.four-plan .col-md-4:nth-child(2) .boxes {
  border-top-color: #2e499c;
}

.main-plan .plan-price1-3 {
  font-size: 18px;
  float: left;
  line-height: 20px;
  margin-right: 30px;
  color: #cdcdcd;
  font-weight: 300;
  padding-top: 32px;
}
.main-plan .plan-price1-4 {
  display: block;
}
/*************************

	Three colum Packages

*************************/

.three-plan {
  padding: 100px 0;
}
.three-plan h3 {
  padding: 0px;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.three-plan ul {
  list-style: none;
  background: #fff;
}
.three-plan li {
  padding: 0px;
}
.three-plan .boxes {
  background: #fff;
  margin-top: 30px;
}
.pricing-table1 {
  background: #ffc600;
  text-align: center;
  color: #fff;
  font-size: 26px;
  padding: 10px 0px;
  font-weight: 600;
}
.pricing-table1 strong {
  display: block;
  font-size: 16px;
  font-weight: 400;
}
.pricing-detail {
  list-style: none;
}
.pricing-detail li {
  display: block;
  text-align: center;
}
.pricing-unit1 {
  font-size: 57px;
  float: left;
  color: #ffc600;
  margin-left: 20px;
  padding-top: 80px;
  font-weight: 300;
}
.pricing-unit1-1 {
  font-size: 132px;
  font-weight: bold;
  color: #ffc600;
  float: left;
}
.pricing-unit1-2 {
  font-size: 18px;
  line-height: 20px !important;
  color: #cdcdcd;
  float: left;
  font-weight: 300;
  padding-top: 100px;
}
.pricing-unit1-3 {
  display: block;
}
.pricing-unit2 {
  font-size: 57px;
  float: left;
  color: #00aeff;
  margin-left: 20px;
  padding-top: 80px;
  font-weight: 300;
}
.pricing-unit2-1 {
  font-size: 132px;
  font-weight: bold;
  color: #00aeff;
  float: left;
}
.pricing-unit2-2 {
  font-size: 18px;
  line-height: 20px !important;
  color: #cdcdcd;
  font-weight: 300;
  padding-top: 100px;
}
.pricing-unit2-3 {
  display: block;
}
.pricing-unit3 {
  font-size: 57px;
  float: left;
  color: #56d700;
  margin-left: 20px;
  padding-top: 80px;
  font-weight: 300;
}
.pricing-unit3-1 {
  font-size: 132px;
  font-weight: bold;
  color: #56d700;
  float: left;
}
.pricing-unit3-2 {
  font-size: 18px;
  line-height: 20px !important;
  color: #cdcdcd;
  font-weight: 300;
  padding-top: 100px;
}
.pricing-unit3-3 {
  display: block;
}
.three-plan .plan-name-dt {
  font-size: 36px;
  font-weight: bold;
  color: #5c5c5c;
  padding: 0px 82px;
  padding-top: 75px;
  line-height: 44px;
  padding-bottom: 39px;
}
.three-plan .plan-good i {
  font-size: 18px;
  color: #a8a8a8;
  margin-right: 12px;
}
.three-plan .plan-pages {
  font-size: 16px;
  line-height: 43px;
  padding-left: 70px;
  font-weight: 300;
  color: #a8a8a8;
  border-top: 1px solid #f5f5f5;
  padding-top: 3px;
}
.three-plan .plan-detail {
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  font-weight: bold;
  color: #868686;
  border-top: 1px solid #f5f5f5;
  padding-top: 3px;
}
.three-plan .plan-detail1 {
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  font-weight: bold;
  color: #868686;
  border-top: 1px solid #f5f5f5;
  padding-top: 3px;
}
.three-plan .plan-detail1 i {
  color: green !important;
}
.three-plan .plan-detail i {
  color: red !important;
}
.pricing-table1-2 {
  background: #000;
  text-align: center;
  color: #fff;
  font-size: 26px;
  padding: 10px 0px;
  font-weight: 600;
}
.pricing-table1-2 strong {
  display: block;
  font-size: 16px;
  font-weight: normal;
}
.pricing-table1-3 {
  background: #56d700;
  text-align: center;
  color: #fff;
  font-size: 26px;
  padding: 10px 0px;
  font-weight: 600;
}
.pricing-table1-3 strong {
  display: block;
  font-size: 16px;
  font-weight: normal;
}
.three-plan .plan-pages i {
  font-size: 18px;
  color: #a8a8a8;
  margin-right: 12px;
}
.three-plan .plan-pages1 {
  display: block;
  font-size: 16px;
  line-height: 43px;
  font-weight: bold;
  color: #e2e2e2;
}
.three-plan .order-1 {
  background: #ffc600;
  font-size: 16px;
  padding: 25px 0px;
  font-weight: bold;
  text-transform: uppercase;
}
.three-plan .order-1 a {
  background: #000;
  border-radius: 5px;
  padding: 12px 32px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.three-plan .order-2 {
  background: #000;
  font-size: 16px;
  padding: 25px 0px;
  font-weight: bold;
  text-transform: uppercase;
}
.three-plan .order-2 a {
  background: #00aeff;
  border-radius: 5px;
  padding: 12px 32px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.three-plan .order-3 {
  background: #56d700;
  font-size: 16px;
  padding: 25px 0px;
  font-weight: bold;
  text-transform: uppercase;
}
.three-plan .order-3 a {
  background: #000;
  border-radius: 5px;
  padding: 12px 32px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
/* =======================

		Blog Listing

======================= */

.blogWraper {
  margin-bottom: 40px;
}
.blogList {
  list-style: none;
}
.blogList li .bloginner {
  background: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.blogList li .bloginner:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-color: #2e499c;
}
.postimg {
  position: relative;
  display: inline-block;
}
.postimg .date {
  width: 60px;
  height: 60px;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  padding: 7px 10px 0 10px;
  text-align: center;
  line-height: 20px;
  background: #2e499c;
  letter-spacing: 0.4px;
  outline: 3px solid #2e499c;
  border: 1px solid #fff;
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 12;
}
.post-header {
  margin-bottom: 20px;
}
.post-header h2 {
  margin-top: 15px;
}
.post-header h4 a {
  font-size: 20px;
  color: #2e499c;
  font-weight: 600;
  display: block;
  margin-top: 10px;
}
.post-header h4 a:hover {
  color: #333;
}
.postmeta {
  margin: 15px 0 20px 0;
  line-height: 24px;
}
.postmeta span,
.postmeta a {
  color: #2e499c;
}
.blogList p,
.blogGrid li p {
  line-height: 24px;
  color: #333;
}
.blogList .readmore,
.blogGrid .readmore {
  border-bottom: 1px dotted #00b1f1;
  display: inline-block;
  margin-top: 15px;
  color: #000;
  font-weight: 600;
  padding-bottom: 5px;
}
.blogList .readmore:hover,
.blogGrid .readmore:hover {
  text-decoration: none;
  color: #00b1f1;
}
/* =======================

		Blog Grid

======================= */

.blogGrid {
  list-style: none;
}
.blogGrid li {
  margin-bottom: 30px;
}
.blogGrid li .int {
  background: #fff;
  padding: 20px;
  border: 1px solid #e4e4e4;
}
.blogGrid li .int:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-color: #2e499c;
}
.blogGrid li .post-header {
  margin-top: 10px;
}
/* =======================

		Blog Detail

======================= */

.blogdetailbox {
  background: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #e4e4e4;
}
blockquote {
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0;
  padding: 20px 20px 20px 60px;
  position: relative;
  border: 3px solid #00b1f1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}
blockquote p {
  font-style: italic;
  display: inline-block;
  color: #000;
}
blockquote:before {
  color: #ddd;
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 22px;
  font-style: normal;
  left: 24px;
  position: absolute;
  top: 20px;
  transform: scale(-1);
}
blockquote:after {
  color: #ddd;
  content: "\f10e";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 22px;
  font-style: normal;
  margin: 0 0 0 8px;
  position: relative;
  top: 3px;
}
.comments h4 {
  color: #222222;
  font-size: 24px;
  font-weight: 600;
}
.comments .media-body h4 span {
  font-size: 12px;
  color: #777777;
  margin-left: 20px;
  display: inline-block;
  width: 100%;
}
.comments {
  margin-top: 30px;
}
.comments .media-list {
  margin-bottom: 30px;
}
.comments .media-list li {
  padding: 20px;
  border: 1px solid #e9e9e9;
  position: relative;
  background: #fff;
}
.comments .media-left {
  margin-right: 20px;
}
.comments .media-left a {
  width: 100px;
  display: inline-block;
}
.comments .media p {
  margin-top: 5px;
  color: #333;
  line-height: 20px;
}
.comments .media h6 span {
  font-size: 13px;
  color: #999;
  margin: 0;
  margin-top: 10px;
  text-transform: none;
  display: inline-block;
  width: 100%;
}
.comments .media {
  margin-top: 30px;
}
.comments .media h6 {
  font-weight: 600;
  color: #00b1f1;
  font-size: 16px;
  text-transform: none;
}
.comments .btn.raply {
  padding: 10px 12px;
  font-size: 12px;
  color: #333;
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  position: absolute;
  top: 15px;
  right: 0;
}
.comments .btn.raply:hover {
  background: #00b1f1;
  color: #fff;
}
.comments .media a img {
  margin-bottom: 10px;
}
.commnetsfrm {
  background: #fff;
  padding: 25px;
}
.comments form label {
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  font-size: 12px;
}
.comments form .form-control {
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  border-radius: 0px;
  box-shadow: none;
  background: none;
  border: 1px solid #e8e8e8;
}
.comments form textarea.form-control {
  display: inline-block;
  width: 100%;
  height: 150px;
}
.commnetsfrm .btn {
  background: #00b1f1;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
  border-radius: 0;
  font-size: 18px;
  padding: 8px 18px;
}
/************************************************

           ==Blog sidebar css==

*************************************************/

.search {
  position: relative;
  margin-bottom: 15px;
}
.search .form-control {
  border-radius: 0;
  height: auto;
  padding: 10px 12px;
  border-color: #ddd;
}
.search .btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;
  background: none;
  font-size: 20px;
}
/*categories*/

.categories li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}
.categories li a {
  margin-top: 0;
  color: #888;
  font-weight: 600;
}
.categories li:before {
  content: "\f0da ";
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  color: #2e499c;
  font-size: 12px;
}
.categories li a:hover {
  color: #2e499c;
}
/*Archives*/

.archive li {
  line-height: 45px;
}
.archive li a {
  margin-top: 0;
  color: #888;
  font-weight: 600;
}
.archive li span {
  float: left;
  height: 30px;
  width: 30px;
  text-align: center;
  background: #f5f5f5;
  border: 1px solid #fff;
  outline: 3px solid #f5f5f5;
  margin-right: 20px;
  line-height: 26px;
  font-size: 12px;
  color: #777777;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.archive li a:hover span {
  background: #2e499c;
  color: #fff;
}
/*Popular Posts*/

.papu-post {
}
.papu-post .media-left {
  margin-right: 0px;
}
.papu-post .media-left img {
  width: 100%;
}
.papu-post .media-left a {
  width: 80px;
  margin-top: 0;
  margin-right: 5px;
  display: inline-block;
}
.papu-post span {
  color: #333;
  letter-spacing: 0px;
  font-size: 11px;
}
.papu-post li {
  border-bottom: 1px solid #ececec;
  padding: 20px 0;
  margin: 0px;
}
.papu-post .media-heading {
  display: inline-block;
  width: 100%;
  color: #00b1f1;
  margin-top: 0px;
  line-height: 20px;
}
.papu-post li:last-child {
  border-bottom: 0;
}
/*Tags*/

.tags:after {
  display: table;
  clear: both;
  content: "";
}
.tags li {
  display: inline-block;
  float: left;
}
.tags a {
  color: #969595;
  border: 1px solid #dadada;
  padding: 7px 10px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.tags a:hover {
  border: 1px solid #2e499c;
  background: #2e499c;
  color: #fff;
  text-decoration: none;
}
/*Photo Streen*/

.photo-steam {
  margin: 0 -5px;
}
.photo-steam:after {
  display: table;
  clear: both;
  content: "";
}
.photo-steam li {
  float: left;
  width: 25%;
  padding: 0 5px;
  margin-bottom: 10px;
}
/************************************************

           == Dashboard Page css==

*************************************************/
.usernavwrap {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}
.usernavdash {
  margin-bottom: 0;
}
.usernavdash li a {
  border-bottom: none;
  padding: 10px 15px;
  color: #444;
  display: block;
}

.usernavdash li a:hover {
  color: #2e499c;
  text-decoration: none;
}
.usernavdash li i {
  margin-right: 2px;
  font-size: 18px;
  width: 25px;
  /* color: #999; */
  color: black;
}
.usernavdash li.active a {
  color: #2e499c;
}
.usernavdash li.active a i,
.usernavdash li a:hover i {
  color: #2e499c;
}
.fade:not(.show) {
  opacity: 1;
}
.profbox {
  background: #f1f5ff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.profbox h3 {
  font-size: 22px;
  color: #515050;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
}
.profbox h3 i {
  font-size: 24px;
  margin-right: 10px;
  opacity: 0.6;
}
.profbox h3 a {
  float: right;
  font-size: 14px;
}
.profbox h3 a i {
  font-size: 14px;
  margin-right: 5px;
}

.recomndjobs li {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
  font-size: 14px;
}
.recomndjobs li h4 a {
  display: block;
  color: #2166a4;
  font-size: 16px;
  font-weight: 600;
}
.recomndjobs li p {
  margin-bottom: 0;
  color: #828282;
}
.recomndjobs li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.recomndjobs li:after {
  clear: both;
  content: "";
  display: table;
}

.followinglist li {
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}
.followinglist li span {
  font-weight: 700;
}

.profilestat li .inbox {
  text-align: center;
  background: #f3f6ff;
  padding: 15px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid #eee;
  transition: all 0.4s ease 0s;
}
.profilestat li .inbox:hover {
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.profilestat li i {
  font-size: 36px;
  color: #999;
  margin-bottom: 15px;
  display: block;
}
.profilestat li h6 {
  font-size: 30px;
  font-weight: 600;
  color: #2e499c;
  margin-bottom: 10px;
}
.profilestat li h6 span {
  font-size: 18px;
  color: #999;
}
.profilestat li strong {
  display: block;
  font-size: 13px;
  color: #333;
}
.instoretxt {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  background: #fcfdff;
  border: 1px solid #eee;
  padding: 30px 20px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0px 18px 40px rgb(25 15 9 / 10%);
}
.instoretxt .credit {
  font-size: 24px;
  margin-bottom: 20px;
}
.instoretxt .currency {
  font-size: 16px;
  font-weight: 700;
}
.instoretxt .currency strong {
  color: #2e499c;
}
.instoretxt strong {
  font-weight: 700;
  color: #2e499c;
}
.instoretxt a {
  font-size: 18px;
  display: inline-block;
  padding: 9px 20px;
  background: #2e499c;
  color: #fff;
  margin: 0 5px;
}
.instoretxt a:hover {
  background: #333;
  text-decoration: none;
}
.instoretxt a.history {
  background: #444;
}
.myads h3 {
  font-size: 24px;
  margin: 0 0 10px 0;
}
/* The switch - the box around the slider */

.switchbox {
  padding: 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.switchbox .txtlbl {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.switchbox .txtlbl i {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.switchbox strong {
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 56px;
  height: 20px;
  padding: 3px;
  background-color: white;
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
  background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
  background-image: -o-linear-gradient(top, #eeeeee, white 25px);
  background-image: linear-gradient(to bottom, #eeeeee, white 25px);
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px !important;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 10px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 10px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #47a8d8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}
.switch-input:checked ~ .switch-handle {
  left: 33px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

/************************************************
           ==Subscribe css==
*************************************************/

.subscribe {
  padding: 70px 50px;
  /* background: #2e499c url(../images/newsletter-bg.png) no-repeat center; */
  background-size: cover;
  text-align: center;
  border-radius: 30px;
}
.subscribe h6 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 5px;
}
.subscribe p {
  color: #fff;
}

.subscribe .form-control {
  height: auto;
  padding: 15px 13px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-right: 1px;
  border-right: 1px solid #ddd;
}
.subscribe .btn {
  background: #2e499c;
  padding: 15px 20px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0 5px 5px 0;
  width: 100%;
}
.subscribe .btn:hover {
  background: #000;
  color: #fff;
}
#subscribe_newsletter_form {
  margin-top: 8px;
}

/************************************************
/************************************************

           ==Footer css==

*************************************************/

.footerWrap {
  background: #f2f6fd;
  padding: 50px 0;
}
.footerWrap h5 {
  font-size: 18px;
  font-weight: bold;
  color: #212529;
  margin-bottom: 15px;
}
.footerWrap p {
  line-height: 22px;
  color: #ccc;
}
.footerWrap .ft-logo {
  margin-bottom: 15px;
}

.footerWrap .address {
  margin-bottom: 20px;
  padding-top: 8px;
  color: #212529;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
}
.footerWrap .address:before {
  position: absolute;
  left: 0;
  content: "\f041";
  font-family: "FontAwesome";
  font-size: 18px;
}

.footerWrap .email {
  margin-bottom: 20px;
  color: #212529;
  position: relative;
  padding-left: 30px;
}
.footerWrap .email:before {
  position: absolute;
  left: 0;
  content: "\f003";
  font-family: "FontAwesome";
  font-size: 18px;
}

.footerWrap .email a,
.footerWrap .phone a {
  color: #212529;
}
.footerWrap .phone {
  margin-bottom: 20px;
  color: #212529;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  padding-left: 30px;
}
.footerWrap .phone:before {
  position: absolute;
  left: 0;
  content: "\f095";
  font-family: "FontAwesome";
  font-size: 18px;
}

.social {
  margin-top: 20px;
}
.social a {
  color: #2e499c;
  font-size: 32px;
  display: inline-block;
  margin-right: 5px;
}
.social a:hover {
  color: #36b3e5;
}
.sidebar .social a {
  color: #444;
}
/*Articles*/

.posts-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.posts-list li {
  border: none;
  margin: 15px 0;
}
.posts-list .media-left {
  margin-right: 30px;
  width: 80px;
  float: left;
  padding-right: 0;
}
.posts-list .entry-title {
  font-weight: 400;
  text-transform: inherit;
  margin: 0 0 5px;
}
.post .entry-title a {
  color: #fff;
  font-size: 16px;
}
.posts-list .entry-meta {
  padding: 0;
}
.posts-list .entry-date {
  color: #ccc;
  font-style: italic;
  text-transform: capitalize;
}
.btad {
  text-align: center;
  margin-bottom: 30px;
}
.footerWrap .quicklinks {
  list-style: none;
}
.footerWrap .quicklinks li {
}
.footerWrap .quicklinks li a {
  display: block;
  color: #888;
  padding: 8px 0;
  font-size: 14px;
}

.footerWrap .quicklinks li a:hover {
  color: #2e499c;
  text-decoration: none;
}
.copyright {
  padding: 10px 0;
  color: #777;
}
.copyright ul li {
  display: inline;
}
.copyright ul li a {
  color: #555;
  padding: 0 5px;
  display: inline-block;
  margin: 5px;
}
.copyright a:hover {
  color: #000;
}
.paylogos {
  text-align: right;
}
.bttxt {
  color: #888;
  line-height: 24px;
}
/*copyright*/

.copyright {
  padding: 20px 0;
}

.help-block-error {
  color: #fe0004;
}

/*******************

	Large Screen

*******************/

@media screen and (max-width: 1199px) {
  .main-plan .plan-price2 {
    margin-left: 0px;
    font-size: 44px;
  }
  .main-plan .plan-price2-1 {
    font-size: 70px;
  }
  .main-plan .plan-price2-2 {
    margin-right: 0px;
  }
  .main-plan .plan-price {
    margin-left: 0px;
    font-size: 44px;
  }
  .main-plan .plan-price-1 {
    font-size: 70px;
  }
  .main-plan .plan-price-2 {
    margin-right: 0px;
  }
  .main-plan .plan-price1-1 {
    margin-left: 0px;
    font-size: 44px;
  }
  .main-plan .plan-price1-2 {
    font-size: 70px;
  }
  .main-plan .plan-price1-3 {
    margin-right: 0px;
  }
  .pricing-unit1 {
    margin-left: 5px;
  }
  .pricing-unit2 {
    margin-left: 5px;
  }
  .pricing-unit3 {
    margin-left: 5px;
  }
}

/*******************

	Monitor Screen

*******************/

@media screen and (max-width: 1024px) {
  .usernav li a {
    padding: 19px 14px;
  }
  .jobslist li .applybtn {
    margin-top: 15px;
  }
  .pageSearch a.btn {
    margin-bottom: 15px;
  }
}

/*******************

	Tablet Screen

*******************/

@media screen and (max-width: 990px) {
  body {
    font-size: 16px;
  }
  .header .logo {
    text-align: center;
    float: left;
  }
  .navbar-header {
    float: right;
    display: block;
    margin-top: 5px;
  }

  .navbar-toggler {
    background: #eee;
    padding: 10px;
    border-radius: 5px;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .navbar-default {
    float: none;
  }
  .three-plan li {
    padding: 0 15px;
    margin-top: 15px;
  }
  .three-plan ul {
    background: none;
  }
  .main-plan {
    max-width: 150px;
    margin: 0 auto;
  }
  .main-unit {
    max-width: 240px;
    height: 200px;
    margin: 0 auto;
  }
  /*Search*/
  .searchblack {
    margin-top: 30px;
  }
  .searchwrap {
    padding: 40px 0;
    height: auto;
  }

  .searchwrap .searchtpimg {
    display: none;
  }
  .section {
    padding: 40px 30px;
  }

  .searchbar .btn {
    width: 100%;
  }
  ul.orderlist li {
    float: none;
    width: auto;
  }
  .optionlist li {
    font-size: 14px;
  }
  .searchList li p {
    font-size: 16px;
  }
  .sidebar a {
    font-size: 16px;
  }
  .sidebar .social a {
    font-size: 30px;
  }
  .pageTitle h1,
  .breadCrumb {
    text-align: center;
  }
  /*job detail*/

  .job-header .jobinfo .companyinfo {
    text-align: left;
    margin-top: 30px;
    border-top: 1px solid #eee;
    padding-top: 30px;
  }
  .whatList li {
    margin-bottom: 30px;
  }
  .about-wraper h2 {
    margin-top: 20px;
  }
  .postimg {
    margin-top: 15px;
  }
  .about-wraper,
  .textrow {
    padding: 50px 0;
  }
  .appwraper .appbtn {
    margin-top: 30px;
  }
  .appwraper .titleTop h3 {
    font-size: 40px;
  }
  .appwraper .subtitle2 {
    font-size: 24px;
  }
  /*Footer*/

  .footerWrap h5 {
    margin-top: 25px;
    font-size: 24px;
  }

  .slidertext1 {
    font-size: 30px;
    line-height: 32px !important;
  }
  .caption {
    padding: 15px !important;
  }
}

/*******************

Large Mobile Screen

*******************/

@media screen and (max-width: 768px) {
  .topsearchwrap h5 {
    margin-top: 30px;
  }
  .navbar-nav > li {
    padding: 0 10px;
  }
  .pagiWrap {
    margin-bottom: 30px;
  }
  .jobButtons a {
    font-size: 15px;
    margin-right: 0px;
    padding: 6px 10px;
  }
  .post-header h4 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-nav li.postjob,
  .navbar-nav li.jobseeker {
    margin-bottom: 10px;
  }
  .dropdown-menu {
    position: static;
    float: none;
    width: 100%;
  }
  .usernav li a {
    padding: 12px 15px;
  }
  .titleTop h3 {
    font-size: 36px;
  }
  .catelist {
    margin-bottom: 30px;
  }
  .jobslist li {
    text-align: center;
  }
  .jobslist li h4 {
    margin-top: 5px;
  }
  .jobslist li .col-md-7,
  .jobslist li .col-md-9 {
    padding-left: 15px;
  }
  .main-plan .plan-price2 {
    margin-left: 0px;
    font-size: 44px;
  }
  .pagiWrap .showreslt {
    margin-bottom: 10px;
  }
  .searchform .form-control {
    margin-bottom: 15px;
  }
  .job-header .jobinfo {
    padding: 15px;
  }
  .job-header .contentbox {
    padding: 20px;
  }
  .searchbar .form-control {
    width: 100%;
  }
  .searchbar select.form-control {
    border-left: none;
    border-top: 1px solid #eee;
  }
  .searchbar .btn {
    margin-left: 0 !important;
  }

  #subscribe_newsletter_form .input-group {
    display: block;
  }
  .subscribe .form-control {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px !important;
  }

  .searchwrap {
    background-position: top;
  }

  /*sidebar*/

  .sidebar {
    margin-top: 30px;
  }
  .text-right {
    text-align: left;
  }
  /*Photo Streem*/

  .photo-steam li {
    width: 50%;
  }
  .appimg {
    display: none;
  }
  .appwraper .appbtn a {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .pull-right {
    float: none !important;
  }
  .navbar-collapse {
    padding: 0 15px;
  }
  .comments .media-list li.margin-left80 {
    margin-left: 0;
  }
}

/*******************

	Mobiles Screen

*******************/

@media screen and (max-width: 560px) {
  .jobButtons a {
    display: block;
    margin-right: 0;
    margin-top: 10px;
  }
  .searchwrap h3 {
    font-size: 24px;
  }
  .searchbar .input-group {
    display: block;
  }
  .searchbar .btn {
    margin: 15px 0 0 0;
  }

  .usernav li a {
    padding: 10px 10px;
    text-transform: none;
  }
  .error-page-wrap .errormain h2 {
    font-size: 150px;
    line-height: 160px;
  }
  .error-page-wrap .errormain h3 {
    font-size: 28px;
  }
  .searchList li {
    text-align: center;
  }
  .searchList li .jobimg {
    float: none;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .job-header .jobinfo .companyinfo,
  .job-header .jobinfo .candidateinfo,
  .educationList li {
    text-align: center;
  }
  .job-header .jobinfo .companyinfo .companylogo,
  .job-header .jobinfo .candidateinfo .userPic,
  .educationList li .date {
    float: none;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .job-header .jobinfo .candidateinfo .userPic {
    height: auto;
  }
  .employerList {
    margin: 15px 0;
  }
  .copyright ul li a {
    background: #ddd;
    color: #000;
    padding: 8px 15px;
    margin: 5px;
    display: block;
  }
  .copyright ul li a:hover {
    background: #333;
    color: #fff;
    text-decoration: none;
  }
  .appwraper .titleTop h3 {
    font-size: 36px;
  }
  .appwraper {
    padding-top: 50px;
  }
  .appimg {
    display: none;
  }

  .nav-tabs > li,
  .tabswrap .nav-tabs {
    float: none;
  }
  .tabswrap .nav-tabs > li {
    margin-top: 5px;
  }
}

@media screen and (max-width: 400px) {
  .header .col-md-5 {
    padding-right: 15px;
  }
  .usernav li {
    width: 50%;
    text-align: center;
  }
}
